<template>
  <b-container>
    <b-row>
      <b-col>
        <b-input-group class="mt-3">
          <b-input-group-prepend>
            <b-button v-b-toggle.sidebar-1
                      :variant="activeSettingsVariant">
              <b-icon icon="gear"></b-icon>
              <!--<b-badge class="button-badge" v-if="activeSettings > 0" variant="success">{{ activeSettings }}</b-badge>-->
            </b-button>
          </b-input-group-prepend>
          <b-input-group-prepend>
            <b-button :variant="calendarButtonVariant"
                      @click="toggleCalendar">
              <b-icon icon="calendar3"></b-icon>
            </b-button>
          </b-input-group-prepend>
          <b-form-input
              id="name"
              v-model="name"
              v-if="parseInt(enableMultiline) === 0"
              @keyup="setChecksum" @keyup.enter="onSubmit"
              placeholder="Begriff / Nummer">
          </b-form-input>
          <b-form-textarea
              rows="1"
              max-rows="8"
              v-model="namesText"
              v-if="parseInt(enableMultiline)  === 1"
              @keyup="setMultiline">
          </b-form-textarea>
          <b-input-group-append>
            <b-button
                variant="success"
                v-if="parseInt(enableMultiline)  === 0"
                @click="onSubmit" :disabled="!name">
              <b-icon icon="check"></b-icon>
            </b-button>
            <b-button
                variant="success"
                v-if="parseInt(enableMultiline)  === 1"
                @click="onSubmitMulti" :disabled="!namesText">
              <b-icon icon="check"></b-icon>
            </b-button>

          </b-input-group-append>
          <b-input-group-append>
            <b-button v-b-toggle.sidebar-2
                      variant="outline-secondary"
                      @click="setupTable">
              <b-icon icon="info-circle"></b-icon>
            </b-button>
          </b-input-group-append>


        </b-input-group>

        <b-calendar
            class="mt-3 mb-3"
            v-if="showCalendar"
            v-model="name"
            selected-variant="success"
            today-variant="info"
            nav-button-variant="secondary"
            block
            label-help=""
            locale="de"
            aria-controls="name"
            start-weekday="1"
            :show-decade-nav="true"
            :hide-header="true"
            @input="setDate"
            :date-disabled-fn="dateDisabled"
            :date-info-fn="dateClass"
        ></b-calendar>

        <div v-if="parseInt(enableMultiline)  === 0 && parseInt(enableMultiSum) === 0">
          <b-alert
              variant="secondary"
              class="mt-3 mb-3"
              v-if="name && target === 0" show>
            {{ prefix }}{{ name }}{{ suffix }} = {{ sum }} = {{ number }}
          </b-alert>
          <b-alert
              variant="success"
              class="mt-3 mb-3"
              v-if="name && (target !== 0 && target === number)" show>
            {{ prefix }}{{ name }}{{ suffix }} = {{ sum }} = {{ number }}
          </b-alert>
          <b-alert
              variant="warning"
              class="mt-3 mb-3"
              show
              v-if="name && (target !== 0 && target !== number)">
            {{ prefix }}{{ name }}{{ suffix }} = {{ sum }} = {{ number }}
          </b-alert>
        </div>
        <div v-if="parseInt(enableMultiline)  === 1 && parseInt(enableMultiSum) === 0">
          <div v-for="name in names" :key="name">
            <b-alert
                variant="secondary"
                class="mt-3 mb-3"
                v-if="name.name && target === 0" show>
              {{ prefix }}{{ name.name }}{{ suffix }} = {{ name.sum }} = {{ name.number }}
            </b-alert>
            <b-alert
                variant="success"
                class="mt-3 mb-3"
                v-if="name.name && (target !== 0 && target === name.number)"
                show>
              {{ prefix }}{{ name.name }}{{ suffix }} = {{ name.sum }} = {{ name.number }}
            </b-alert>
            <b-alert
                variant="warning"
                class="mt-3 mb-3"
                show v-if="name.name && (target !== 0 && target !== name.number)">
              {{ prefix }}{{ name.name }}{{ suffix }} = {{ name.sum }} = {{ name.number }}
            </b-alert>
          </div>
        </div>
        <div v-if="parseInt(enableMultiline)  === 0 && parseInt(enableMultiSum) === 1">
          <b-alert
              variant="secondary"
              class="mt-3 mb-3"
              v-if="name && targets === ''" show>
            {{ prefix }}{{ name }}{{ suffix }} = {{ sum }} = {{ number }}
          </b-alert>
          <b-alert
              variant="success"
              class="mt-3 mb-3"
              v-if="name && (targets !== '' && targetsArr.includes(number))"
              show>
            {{ prefix }}{{ name }}{{ suffix }} = {{ sum }} = {{ number }}
          </b-alert>
          <b-alert
              variant="warning"
              class="mt-3 mb-3"
              show v-if="name && (targets !== '' && !targetsArr.includes(number))">
            {{ prefix }}{{ name }}{{ suffix }} = {{ sum }} = {{ number }}
          </b-alert>
        </div>
        <div v-if="parseInt(enableMultiline)  === 1 && parseInt(enableMultiSum) === 1">
          <div v-for="name in names" :key="name">
            <b-alert
                variant="secondary"
                class="mt-3 mb-3"
                v-if="name.name && targets === ''" show>
              {{ prefix }}{{ name.name }}{{ suffix }} = {{ name.sum }} = {{ name.number }}
            </b-alert>
            <b-alert
                variant="success"
                class="mt-3 mb-3"
                v-if="name.name && (targets !== '' && targetsArr.includes(name.number))"
                show>
              {{ prefix }}{{ name.name }}{{ suffix }} = {{ name.sum }} = {{ name.number }}
            </b-alert>
            <b-alert
                variant="warning"
                class="mt-3 mb-3"
                show
                v-if="name.name && (targets !== '' && !targetsArr.includes(name.number))">
              {{ prefix }}{{ name.name }}{{ suffix }} = {{ name.sum }} = {{ name.number }}
            </b-alert>
          </div>
        </div>

        <b-table class="mt-3"
                 id="main-table"
                 primary-key="id"
                 hover
                 responsive="sm"
                 :items="items"
                 :fields="fields"
                 :filter-function="filterTable"
                 :filter="filterEnabled"
                 v-show="items.length"
                 @filtered="onFiltered"
                 :tbody-tr-class="rowClass">
          <template #cell(actions)="data">
            <b-button
                variant="danger"
                size="sm"
                @click="deleteRow(data.item.id)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
        <b-row v-if="items.length" class="mt-3 mb-3">
          <b-col>
            <b-button variant="success" @click="exportTable">
              Export
            </b-button>
          </b-col>
          <b-col class="text-center">
            <b-form-checkbox v-model="filterEnabled" unchecked-value="" value="on" name="check-button" button>
              Filter
              <b-icon icon="x" v-if="filterEnabled !== ''"></b-icon>
            </b-form-checkbox>
          </b-col>
          <b-col class="text-right">
            <b-button variant="danger" @click="clearTable">Leeren</b-button>
          </b-col>
        </b-row>


      </b-col>
    </b-row>
    <b-sidebar id="sidebar-1" title="Einstellungen" shadow backdrop>
      <div class="px-3 py-2 d-flex flex-column align-items-end h-100">
        <div class="w-100">
          <b-form-group
              id="input-group-1"
              label="Prefix"
              label-for="input-1"
          >
            <b-form-input
                id="input-1"
                v-model="prefix"
                type="text"
                @keyup="setChecksum"
                placeholder="Dieser Text wird immer vorangestellt"
            ></b-form-input>
          </b-form-group>
          <b-form-group
              id="input-group-2"
              label="Suffix"
              label-for="input-2"
          >
            <b-form-input
                id="input-2"
                v-model="suffix"
                type="text"
                @keyup="setChecksum"
                placeholder="Dieser Text wird immer nachgestellt"
            ></b-form-input>
          </b-form-group>
          <b-form-group
              id="input-group-3"
              label="Ziel"
              label-for="input-3"
              v-if="!enableMultiSum"
          >
            <b-form-input
                id="input-3"
                v-model.number="target"
                type="number"
                min="1"
                max="9"
                step="1"
                @keyup="setChecksum"
                placeholder="Zielnummer"
            ></b-form-input>

          </b-form-group>
          <b-form-group
              id="input-group-6"
              label="Ziele (durch Komma getrennt)"
              label-for="input-6"
              v-if="enableMultiSum"
          >
            <b-form-input
                id="input-6"
                v-model="targets"
                type="text"
                @keyup="setChecksum"
                placeholder="1,2,3"
            ></b-form-input>

          </b-form-group>
          <b-form-group
              id="input-group-5"
              label=""
              label-for="input-5"
          >
            <b-form-checkbox
                switch
                id="input-5"
                @change="setMultiSumEnabled"
                unchecked-value="0" value="1"
                number v-model="enableMultiSum">Mehrere Ziele
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
              id="input-group-4"
              label=""
              label-for="input-4"
          >
            <b-form-checkbox
                switch
                id="input-4"
                @change="setMultilineEnabled"
                unchecked-value="0" value="1"
                number v-model="enableMultiline">Mehrzeilige Eingabe
            </b-form-checkbox>
          </b-form-group>
          <b-form-group
              id="input-group-cal"
              label=""
              label-for="input-cal"
          >
            <b-form-checkbox
                switch
                id="input-cal"
                @change="countActiveSettings"
                v-model="showCalendar">
              Kalender anzeigen
            </b-form-checkbox>
          </b-form-group>
          <b-button variant="danger" @click="resetSettings">Zurücksetzen</b-button>
        </div>
        <div class="about mt-auto w-100 d-flex justify-content-between pt-5">
          <small>v0.0.3</small>
          <span style="cursor: pointer" v-b-modal.quoteModal @click="showQuote">❤️</span>
        </div>
      </div>
    </b-sidebar>
    <b-sidebar id="sidebar-2" right title="Tabelle" shadow backdrop>
      <div class="px-3 py-2">
        <b-table class="mt-3" striped hover :items="tableData" :fields="dataFields" v-show="tableData.length"></b-table>
      </div>
    </b-sidebar>
    <b-modal
        id="quoteModal"
        centered
        @hidden="resetModal"
        @ok="resetModal"
        body-class="position-static"
        ok-only>
      <template #modal-title>
        Inspiration
        <b-icon icon="info-circle-fill" id="zenquotes-info"></b-icon>
            <b-tooltip target="zenquotes-info">
              Inspirational quotes provided by <a href="https://zenquotes.io/" target="_blank">ZenQuotes API</a>
            </b-tooltip>
      </template>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between align-content-center">
            <b-button
              variant="primary"
              @click="showQuote">
              <b-icon icon="arrow-clockwise"
                      :animation="quoteLoadingAnimation"></b-icon>
            </b-button>
          <b-button
            variant="primary"
            @click="$bvModal.hide('quoteModal')"
          >
            OK
          </b-button>
        </div>
      </template>
      <b-skeleton-wrapper :loading="quote === null">
          <template #loading>
            <div>
              <b-skeleton width="95%" class="d-block d-sm-none"></b-skeleton>
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
            </div>
          </template>

          <div v-if="quote !== null">
            {{ quote.q }}<br>
            <small>&mdash; {{ quote.a }}</small>
          </div>
        </b-skeleton-wrapper>
    </b-modal>
  </b-container>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  name: 'Home',
  components: {
    //downloadExcel: JsonExcel
  },
  data() {
    return {
      name: '',
      names: [],
      namesText: '',
      sum: 0,
      sums: {},
      number: 0,
      numbers: {},
      suffix: '',
      prefix: '',
      target: '',
      targets: '',
      showCalendar: false,
      activeSettings: 0,
      enableMultiline: 0,
      enableMultiSum: 0,
      quote: null,
      filterEnabled: "",
      filteredItems: [],
      fields: [
        {
          key: 'name',
          sortable: true,
          label: 'Begriff'
        },
        {
          key: 'sum',
          sortable: true,
          label: 'Summe',
          tdClass: 'text-right',
          thClass: 'text-right'
        },
        {
          key: 'number',
          sortable: true,
          label: 'Zahl',
          tdClass: 'text-right',
          thClass: 'text-right'
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-right',
          thClass: 'text-right',
          thStyle: {
            maxWidth: '50px',
            width: '50px'
          }
        }
      ],
      items: [],
      dataFields: [
        {
          key: 'letter',
          sortable: true,
          label: 'Buchstabe'
        },
        {
          key: 'value',
          label: 'Wert',
          sortable: true,
          tdClass: 'text-right',
          thClass: 'text-right'
        }
      ],
      tableData: [],
      mapping: {
        a: 1,
        ä: 6,
        b: 2,
        c: 3,
        d: 4,
        e: 5,
        f: 6,
        g: 7,
        h: 8,
        i: 9,
        j: 1,
        k: 2,
        l: 3,
        m: 4,
        n: 5,
        o: 6,
        ö: 2,
        p: 7,
        q: 8,
        r: 9,
        s: 1,
        ß: 2,
        t: 2,
        u: 3,
        ü: 8,
        v: 4,
        w: 5,
        x: 6,
        y: 7,
        z: 8,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        0: 0
      }
    }
  },
  computed: {
    targetsArr: function () {
      if (this.targets === '') {
        return [];
      }
      return this.targets.split(',').map((x) => parseInt(x));
    },
    calendarButtonVariant: function() {
      return this.showCalendar ? 'success' : 'outline-secondary';
    },
    activeSettingsVariant: function() {
      return this.activeSettings > 0 ? 'secondary' : 'outline-secondary';
    },
    quoteLoadingAnimation: function() {
      return this.quote === null ? 'spin' : '';
    }
  },
  mounted() {
    if (localStorage.items) {
      this.items = JSON.parse(localStorage.items);
    }
    if (localStorage.target) {
      this.target = parseInt(localStorage.target);
    }
    if (localStorage.targets) {
      this.targets = localStorage.targets;
    }
    if (localStorage.prefix) {
      this.prefix = localStorage.prefix;
    }
    if (localStorage.suffix) {
      this.suffix = localStorage.suffix;
    }
    if (localStorage.enableMultiline) {
      this.enableMultiline = parseInt(localStorage.enableMultiline)
    }
    if (localStorage.enableMultiSum) {
      this.enableMultiSum = parseInt(localStorage.enableMultiSum)
    }
    if (localStorage.names) {
      this.names = JSON.parse(localStorage.names)
    }
    if (localStorage.namesText) {
      this.namesText = localStorage.namesText
    }
    if (localStorage.filterEnabled) {
      this.filterEnabled = localStorage.filterEnabled
    }
    if (localStorage.showCalendar) {
      this.showCalendar = localStorage.showCalendar === 'true'
    }
    this.countActiveSettings()
  },
  methods: {
    toggleCalendar() {
      this.showCalendar = !this.showCalendar;
      this.countActiveSettings();
    },
    onSubmit(event) {
      event.preventDefault()
      if (this.name !== '') {
        this.setChecksum()
        let item = [{
          id: this.items.length + 1,
          name: this.prefix + this.name + this.suffix,
          sum: this.sum,
          number: this.number
        }]
        this.items = item.concat(this.items)
        this.name = ''
        this.sum = 0
        this.number = 0
        this.persist()
        this.countActiveSettings()
      }
    },
    setDate() {
      this.setChecksum();
      if (this.enableMultiline) {
        let sep = "\n";
        if (this.namesText === '') {
          this.namesText = this.name;
        } else {
          this.namesText += sep + this.name;
        }
        this.setMultiline();
      }
    },
    setSum() {
      let prefix = this.prefix.toLowerCase().replaceAll(/[^a-z0-9äöüÄÖÜß]+|\s+/gm, "")
      let suffix = this.suffix.toLowerCase().replaceAll(/[^a-z0-9äöüÄÖÜß]+|\s+/gm, "")
      let text = this.name.toLowerCase().replaceAll(/[^a-z0-9äöüÄÖÜß]+|\s+/gm, "")
      text = prefix + text + suffix
      let textArray = text.split('')
      this.sum = 0
      textArray.forEach((value) => {
        this.sum = parseInt(this.sum) + parseInt(this.mapping[value])
      })
    },
    dateDisabled(ymd) {
      if ((this.target === 0 || this.target === '') && !this.enableMultiSum) {
        return false;
      }
      if (this.enableMultiSum && this.targetsArr.length === 0) {
        return false;
      }
      let sum = this.calcSum(ymd);
      let checkSum = this.calcChecksum(sum);
      if (this.enableMultiSum) {
        return !this.targetsArr.includes(checkSum);
      } else {
        return checkSum !== this.target;
      }
    },
    dateClass(ymd) {
     return this.dateDisabled(ymd) ?  'table-light' : 'table-success';
    },
    setChecksum() {
      this.setSum()
      if (this.sum === 0) {
        this.number = 0
      } else if (this.sum % 9 === 0) {
        this.number = 9
      } else {
        this.number = this.sum < 10 ? this.sum : this.sum % 9
      }
      this.countActiveSettings()
    },
    setupTable() {
      const regex = new RegExp('[a-zäöüÄÖÜß]+')
      this.tableData = []
      for (let mapping in this.mapping) {
        let letter = mapping
        if (regex.test(letter)) {
          this.tableData.push({
            letter: letter.toUpperCase() + ', ' + letter,
            value: this.mapping[letter]
          })
        }
      }
    },
    persist() {
      localStorage.items = JSON.stringify(this.items);
      localStorage.target = this.target;
      localStorage.targets = this.targets;
      localStorage.suffix = this.suffix;
      localStorage.prefix = this.prefix;
      localStorage.enableMultiline = this.enableMultiline
      localStorage.enableMultiSum = this.enableMultiSum
      localStorage.namesText = this.namesText
      localStorage.filterEnabled = this.filterEnabled
      localStorage.showCalendar = this.showCalendar
      localStorage.names = JSON.stringify(this.names)
    },
    countActiveSettings() {
      let count = 0
      if (this.prefix !== '') {
        count++
      }
      if (this.suffix !== '') {
        count++
      }
      if (this.enableMultiline === 1) {
        count++
      }
      if (this.enableMultiSum === 1) {
        if (this.targets !== '') {
          count++
        }
        count++
      } else {
        if (this.target > 0) {
          count++
        }
      }
      if (this.showCalendar) {
        count++;
      }
      this.activeSettings = count
      this.persist()
    },
    resetSettings() {
      this.prefix = ''
      this.suffix = ''
      this.target = ''
      this.targets = ''
      this.enableMultiSum = 0
      this.enableMultiline = 0
      this.showCalendar = false
      this.countActiveSettings()
    },
    clearTable() {
      this.$bvModal.msgBoxConfirm('Wirklich alle Einträge löschen?', {
        title: 'Tabelle leeren',
        okVariant: 'danger',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(value => {
            if (value) {
              this.items = []
              this.persist()
            }
          })
          .catch(err => {
            console.log(err)
          })
    },
    deleteRow(id) {
      this.items = this.items.filter(item => item.id !== id)
      this.persist()
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (this.enableMultiSum) {
        if (this.targets !== '') {
          let matchesTarget = false
          this.targetsArr.forEach((target) => {
            if (parseInt(target) !== 0 && parseInt(target) === item.number) {
              matchesTarget = true
            }
          })
          return matchesTarget ? 'table-success' : ''
        }
        return
      } else {
        if ((this.target !== 0) && (this.target === item.number)) return 'table-success'
      }
    },
    setMultiline() {
      this.names = []
      let names = this.namesText.split(/\n/)
      names.forEach((value) => {
        let sum = this.calcSum(value)
        this.names.push({
          name: value,
          sum: sum,
          number: this.calcChecksum(sum)
        })
      })
    },
    calcSum(name) {
      let prefix = this.prefix.toLowerCase().replaceAll(/[^a-z0-9äöüÄÖÜß]+|\s+/gm, "")
      let suffix = this.suffix.toLowerCase().replaceAll(/[^a-z0-9äöüÄÖÜß]+|\s+/gm, "")
      let text = name.toLowerCase().replaceAll(/[^a-z0-9äöüÄÖÜß]+|\s+/gm, "")
      text = prefix + text + suffix
      let textArray = text.split('')
      let sum = 0
      textArray.forEach((value) => {
        sum = parseInt(sum) + parseInt(this.mapping[value])
      })
      return sum
    },
    calcChecksum(sum) {
      let number = 0
      if (sum === 0) {
        number = 0
      } else if (sum % 9 === 0) {
        number = 9
      } else {
        number = sum < 10 ? sum : sum % 9
      }
      this.countActiveSettings()
      return number
    },
    onSubmitMulti(event) {
      event.preventDefault()
      this.names.forEach((entry) => {
        if (entry.name !== '') {
          let item = [{
            id: this.items.length + 1,
            name: this.prefix + entry.name + this.suffix,
            sum: entry.sum,
            number: entry.number
          }]
          this.items = item.concat(this.items)
          this.name = ''
          this.sum = 0
          this.number = 0
          this.namesText = ''
          this.names = []
          this.persist()
          this.countActiveSettings()
        }
      })
    },
    setMultilineEnabled() {
      this.enableMultiline = parseInt(this.enableMultiline)
      this.countActiveSettings()
    },
    setMultiSumEnabled() {
      this.enableMultiSum = parseInt(this.enableMultiSum)
      this.countActiveSettings()
    },
    exportTable() {
      let data = [];
      if (this.filterEnabled === 'on') {
        data = this.filteredItems.map((item) => {
          delete item.id;
          return item;
        })
      } else {
         data = this.items.map((item) => {
          delete item.id;
          return item;
        })
      }
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(worksheet, [["Begriff", "Summe", "Zahl"]], {origin: "A1"});
      XLSX.utils.book_append_sheet(workbook, worksheet, "Zahlen");
      XLSX.writeFile(workbook, "Zahlen.xlsx", {compression: true});
    },
    async showQuote() {
      this.quote = null;
      const response = await fetch('https://numbers.10101.at/quote.php');
      var data = await response.json();
      this.quote = data;
    },
    resetModal() {
      this.quote = null;
    },
    filterTable(item) {
      this.persist();
      if (this.enableMultiSum) {
        if (this.targets !== '') {
          let matchesTarget = false
          this.targetsArr.forEach((target) => {
            if (parseInt(target) !== 0 && parseInt(target) === item.number) {
              matchesTarget = true
            }
          })
          return matchesTarget ? 'table-success' : ''
        }
        return
      } else {
        if ((this.target !== 0) && (this.target === item.number)) return 'table-success'
      }
    },
    onFiltered(filteredItems) {
        this.filteredItems = filteredItems
      }
  }
}
</script>

<style lang="scss">
.button-badge {
  margin-left: 10px;
}

tbody td {
  line-height: 31px;
}

@media (prefers-color-scheme: dark) {
  .b-sidebar.bg-light.text-dark {
    background-color: #343a40 !important;
    color: #dee2e6 !important;

    .text-dark {
      color: #dee2e6 !important;
    }
  }
  .table.b-table > thead > tr > [aria-sort=none], .table.b-table > tfoot > tr > [aria-sort=none] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='LightGray' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='LightGray' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
  }
  .table.b-table > thead > tr > [aria-sort=ascending], .table.b-table > tfoot > tr > [aria-sort=ascending] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='LightGray' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='LightGray' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
  }
  .table.b-table > thead > tr > [aria-sort=descending], .table.b-table > tfoot > tr > [aria-sort=descending] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='LightGray' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='LightGray' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
  }
}
</style>
